// Modernizr
import 'jquery-migrate';
import $ from 'jquery';
import Modernizr from 'modernizr';
import _ from 'lodash';
import imagesLoaded from 'imagesloaded';
import { augmentJquery, wrapHistory } from './legacyCodePollyfills/legacyCodePollyfills';
import UfaCaller from '../ufa/UfaCaller';
import { ufa } from '@uberflip/ufa-client';
import Privacy from './privacy';

// Expose ufa global on hubs login
window.ufa = ufa;
window.Hubs = {};

// touch test for backwards compatibility
Modernizr.addTest('touch', () => {
  let bool;
  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {bool = true;} else {
    const query = ['@media (', Modernizr._prefixes.join('touch-enabled),('), 'heartz', ')', '{#modernizr{top:9px;position:absolute}}'].join('');
    Modernizr.testStyles(query, node => {bool = node.offsetTop === 9;});
  }
  return bool;
});
window.Modernizr = Modernizr;

// Add event triggers to our history object
// Event hooks used to control page change logic
wrapHistory(window.self.history);
window.$ = window.jQuery = $;

// Augment jQuery with functionality previously supplied by:
// -- jquery.cookie
// -- jquery.fitvids
// -- jquery.perfectScrollbar
// For backawards compatibility with custom code
augmentJquery.withAllPollyfills($);

/**
 * adds jquery.fn.imagesLoaded to jquery, allowing you to listen for image load events
 */
imagesLoaded.makeJQueryPlugin( $ );
window.imagesLoaded = imagesLoaded;
window._ = _;

require('../../../libs/promise/q.js');
require('../../../libs/timeago/timeago.js');
require('../../../libs/mbp/js/helper.js');
require('../../../libs/iscroll4/iscroll.js');
require('../../../libs/jgestures/jgestures.js');
require('../../../libs/iframeResizer/iframeResizer.contentWindow.js');

require('./constants.js');
require('./privacy_helpers.js');
require('./login/hubs_login.js');

const init = (pageConfig) => {
  const ufaCaller = new UfaCaller({
    ufaUrl: pageConfig.serverUrl.ufa,
    accountId: pageConfig.accountId,
    hubId: pageConfig.hubId,
    pageType: pageConfig.pageType,
    pardotCookie: pageConfig.pardotCookie,
    connectedMAPs: pageConfig.mapIntegrations,
    streamId: pageConfig.streamId || null,
    itemId: pageConfig.itemId || null,
    authorId: pageConfig.authorId || null,
    requireOptIn: UfaCaller.ufaRequiresOptIn(pageConfig)
  });

  if (!pageConfig.isPreventAnalyticsCollectionEnabled) {
    ufaCaller
        .init()
        .trackPageView()
        .trackVisitorData();
  }

  window.Hubs = Object.assign(window.Hubs, {
    Config: pageConfig,
    Privacy: Privacy,
    appInstance: Object.assign({ ufaCaller }, Hubs.PrivacyHelpers),
    Events: {
      on: $.fn.on.bind($(window)) // Allow 'load', 'resize', 'scroll', and method chaining, to still work as expected.
    }
  });
};

export { init }
