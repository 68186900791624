import 'jquery-migrate';
import $ from 'jquery';
import Q from '/app/libs/promise/q';

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Private Vars
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
var $windowEl = null;

// For Positioning the Content:
var $introEl = null;
var marginTop = 0;
var marginMin = 0;
var marginReduction = 30;
var resizeTimer = 0;

// For Submitting Password:
var $inputEl = null;
var $submitBtn = null;
var $errorEl = null;
var textSubmit = '';
var textAuthenticating = '';
var textAuthFailed = '';
var textAuthInvalid = '';
var textRedirecting = '';
var hiddenErrorTop = 0;
var visibleErrorTop = 30;


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Exposed Public Functions
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
/**
 *
 * @public
 * @return undefined
 */
export function initialize() {
    // Get Handles to Elements
    $windowEl = $(window);
    $introEl = $('.intro');
    $inputEl = $('[data-type="passphrase"]');
    $submitBtn = $('[data-action="submit"]');
    $errorEl = $('.error-alert');

    // Position the Content on Load
    positionIntroEl(false);

    // Reposition the Content on Resize
    hookResizeEvent();

    // Hook the Submit Button on Input Field
    hookSubmit();
}
$(initialize); // Invoke at DOM Ready

/**
 *
 * @private
 * @return undefined
 */
export function showError(errorMsg) {
    if (errorMsg === undefined) { errorMsg = textAuthInvalid; }
    $errorEl
        .empty()
        .append('<p><strong>' + textAuthFailed + '</strong> ' + errorMsg + '</p>')
        .animate({'top': visibleErrorTop}, 350);
}

/**
 *
 * @private
 * @return undefined
 */
export function hideError() {
    $errorEl.animate({'top': hiddenErrorTop}, 100);
}


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Private Event Hooks/Handlers
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
/**
 *
 * @private
 * @return undefined
 */
function hookResizeEvent() {
    $windowEl.on('resize.Hubs.Login', function() {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function() {
            positionIntroEl(true);
        }, 250);
    });
}

/**
 *
 * @private
 * @return undefined
 */
function hookSubmit() {
    var performSubmit = function hookSubmitButton_performSubmit() {
        var passphrase = $inputEl.val().trim();

        // Hide Error if already shown
        hideError();

        // Check for Valid Length
        if (passphrase.length < 6 || passphrase.length > 40) {
            return showError();
        }

        // Update Submit Button State
        $submitBtn.addClass('loading').find('span').text(textAuthenticating);

        // Attempt to Login
        attemptLogin(passphrase)
            .then(
                function HubsLogin_hookSubmitButton_ajaxSuccess() {
                    // Update Submit Button Text
                    $submitBtn.find('span').text(textRedirecting);
                },
                function HubsLogin_hookSubmitButton_ajaxFailure() {
                    // Reset Submit Button State & Text
                    $submitBtn.removeClass('loading').find('span').text(textSubmit);
                }
            );
    };

    if (!$submitBtn.length) { return; }

    // Get Text for Display
    textSubmit = $submitBtn.find('span').text();
    textAuthenticating = $submitBtn.attr('data-text-auth') || 'authenticating...';
    textAuthFailed = $submitBtn.attr('data-text-auth-failed') || 'Failed to authenticate:';
    textAuthInvalid = $submitBtn.attr('data-text-auth-invalid') || 'Invalid Password';
    textRedirecting = $submitBtn.attr('data-text-redirect') || 'Entering the Hub...';

    // Get Original Top Value for Error Display
    hiddenErrorTop = parseInt($errorEl.css('top'), 10);

    // Hook Click Event on Button
    $submitBtn.on('click.Hubs.Login', function(e) {
        performSubmit();
    });

    // Hook Keypress Event on Input
    $inputEl.on('keypress.Hubs.Login', function(e) {
        if (e.which === 13) {
            performSubmit();
        }
    });
}


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Private Function Implementations
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
/**
 *
 * @private
 * @param {boolean} fromResizeEvent
 * @return undefined
 */
function positionIntroEl(fromResizeEvent) {
    if (!$introEl.length) { return; }

    // Determine Height of Container and Center Vertically
    marginTop = Math.max((($windowEl.height() - $introEl.outerHeight()) / 2) - marginReduction, marginMin);
    $introEl.css({'margin-top': marginTop});
    if (!fromResizeEvent) {
        $introEl.animate({'opacity': 1}, 500);
    }
}

/**
 *
 * @private
 * @param {string} passphrase
 * @return {object} - A Promise
 */
function attemptLogin(passphrase) {
    // Attempt Login via AJAX
    var promise = Q.invoke($, 'ajax', {
        'url'      : absoluteHubUrl('/hubsFront/ajax_authenticate/passphrase'),
        'type'     : 'POST',
        'cache'    : false,
        'async'    : true,
        'dataType' : 'json',
        'data'     : {'passphrase' : passphrase}
    });

    // Handle AJAX Response
    promise.then(
        function HubsLogin_attemptLogin_ajaxSuccess(data) {
            // Redirect if Valid Password
            if (data && data.response && data.response.redirect) {
                // Redirect
                window.location.href = data.response.redirect;
            } else {
                showError();
            }
        },
        function HubsLogin_attemptLogin_ajaxFailure(data) {
            var response = JSON.parse(data.responseText);
            if (response.meta.error.message) {
                showError(response.meta.error.message[0]);
            }
            else if (response.meta.error.generic) {
                showError(response.meta.error.generic[0]);
            }
            else {
                showError();
            }
        }
    );

    return promise;
}

function absoluteHubUrl(path) {
    var here = window.location.href;

    var loginPathIndex = here.lastIndexOf('/h/login');

    var basePath = here.substr(0, loginPathIndex);

    var absoluteUrl = basePath + path;

    return absoluteUrl;
}
